import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { QueryClientProvider, useQueryClient } from "react-query";
import Homepage from "./component/homepage";
import { userContext } from "./context/user";
import { toastContext } from "./context/toast";
import Layout from "./component/layout";
import { useLogin } from "./api/queries/auth/useLogin";
import api from "./api/client";
import { storage } from "./shared/storage";
import { AuthLoginPayload } from "./api/types/auth";
import { useCallback, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { queryKeys } from "./api/query";
import { TopicLayout } from "./component/topic/list";
import { TopicForm } from "./component/topic/form";
import { TopicDetail } from "./component/topic/detail";
import { SessionLayout } from "./component/session/list";
import { SessionForm } from "./component/session/form";
import { SettingsLayout } from "./component/setting";
import { SessionDetail } from "./component/session/detail";
import { ResponseError } from "./api/types/base";
import { InvitationDetail } from "./component/session/invitation-detail";
import { ItemDetail } from "./component/session/item-detail";
import { ItemForm } from "./component/session/item-form";

export default function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const authLogin = useLogin();
  const toastRef = useRef<Toast>(null);
  const queryClient = useQueryClient();

  const login = async (payload: AuthLoginPayload) => {
    const { token } = await authLogin.mutateAsync(payload);
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    storage.set("token", token);
    queryClient.invalidateQueries(queryKeys.isAuthenticated());
    navigate("/");
  };

  const logout = () => {
    storage.delete("token");
    navigate("/login");
  };

  const hasRole = () => {
    return false;
  };

  const isPublicUrl = useCallback((): boolean => {
    return /\/login|\/registration|\/reset-password|\/set-password/.test(
      pathname,
    );
  }, [pathname]);

  useEffect(() => {
    const defaultOptions = queryClient.getDefaultOptions();

    const onError = ({ response }: any) => {
      if (!response) {
        // TODO: toast
      }

      if (response.data.errors.length > 0) {
        toastRef.current?.show(
          response.data.errors.map((err: ResponseError) => ({
            severity: "error",
            summary: `${err.property} - ${err.message}`,
          })),
        );
      }

      // const { status } = response;
      // const { data, success, message } = response.data as ErrorResponseData;

      // if (status === 401 && !isPublicUrl()) {
      //   toastRef.current?.show({ severity: "error", summary: message });
      //   navigate("/login");
      // } else {
      //   if (!success && data.errors) {
      //     toastRef.current?.show(
      //       data.errors.map((err) => ({
      //         severity: "error",
      //         summary: `${err.property} - ${err.message}`,
      //       })),
      //     );
      //   }
      // }
    };

    if (defaultOptions.queries) {
      defaultOptions.queries.onError = onError;
    }

    if (defaultOptions.mutations) {
      defaultOptions.mutations.onError = onError;
    }
  }, [queryClient, navigate, isPublicUrl]);

  return (
    <QueryClientProvider client={queryClient}>
      <userContext.Provider value={{ detail: {}, login, logout, hasRole }}>
        <toastContext.Provider value={{ ref: toastRef }}>
          <Toast ref={toastRef} />

          <Routes>
            <Route path="/" element={<Layout />}>
              {/* Topics */}
              <Route path="topics" element={<TopicLayout />}>
                <Route path="new" element={<TopicForm />} />
                <Route path=":topicId/detail" element={<TopicDetail />} />
                <Route path=":topicId/edit" element={<TopicForm />} />
              </Route>

              {/* Sessions */}
              <Route path="sessions" element={<SessionLayout />}>
                <Route path="new" element={<SessionForm />} />
                <Route path=":sessionId/detail" element={<SessionDetail />} />
                <Route path=":sessionId/edit" element={<SessionForm />} />
              </Route>

              {/* Invitations */}
              <Route
                path="sessions/:sessionId/invitations/:invitationId/items"
                element={<InvitationDetail />}
              >
                <Route path="new" element={<ItemForm />} />
                <Route path=":itemId/detail" element={<ItemDetail />} />
                <Route path=":itemId/edit" element={<ItemForm />} />
              </Route>

              {/* Settings */}
              <Route path="settings" element={<SettingsLayout />} />

              {/* Homepage */}
              <Route path={""} element={<Homepage />} />
            </Route>
          </Routes>
        </toastContext.Provider>
      </userContext.Provider>
    </QueryClientProvider>
  );
}

import { Toast } from "primereact/toast";
import { createContext, RefObject } from "react";

interface ToastContext {
  ref: RefObject<Toast> | undefined;
}

export const toastContext = createContext<ToastContext>({
  ref: undefined,
});

import { createContext } from "react";
import { AuthLoginPayload } from "../api/types/auth";

export const userContext = createContext<UserContext>({
  detail: {},
  login: async (payload: AuthLoginPayload) => {},
  logout: () => {},
  hasRole: (name: string) => false,
});

export interface UserContext {
  detail: {};
  login: (payload: AuthLoginPayload) => Promise<void>;
  logout: () => void;
  hasRole: (name: string) => boolean;
}

import store from "store";

const GKEY = "isar2";

export const storage = {
  set: (key: string, val: string) => {
    const data = store.get(GKEY) || {};
    data[key] = val;
    store.set(GKEY, data);
  },

  get: (key: string) => {
    const data = store.get(GKEY) || {};

    if (data[key]) {
      return data[key];
    }

    return null;
  },

  delete: (key: string) => {
    const data = store.get(GKEY) || {};
    delete data[key];
    store.set(GKEY, data);
  },
};

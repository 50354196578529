import { useMutation } from "react-query";
import api from "../../client";

const req = async (id: number | undefined): Promise<{}> => {
  const { data } = await api.delete(`/items/${id}`);

  return data;
};

export const useItemDelete = (id: number | undefined) => {
  return useMutation(() => {
    return req(id);
  });
};

import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { useSessionDetail } from "../../api/queries/session/detail";
import { useConstants } from "../../api/queries/enum/constants";

export const SessionDetail = () => {
  const { sessionId } = useParams();
  const navigate = useNavigate();
  const { data: sessionDetailResponse } = useSessionDetail(Number(sessionId));
  const { data: constantsResponse } = useConstants();

  const onEditClick = () => navigate(`/sessions/${sessionId}/edit`);
  const onInvitationClick = () =>
    navigate(
      `/sessions/${sessionId}/invitations/${session?.invitation?.id}/items`,
    );

  const constants = constantsResponse?.results.constants.data;
  const session = sessionDetailResponse?.results.sessionDetail.data;

  return (
    <div className="fadein">
      <div className="col-12">
        <div className="flex gap-3">
          {session?.state === "MIMORADNE" && (
            <Button
              label="Upravit"
              className="p-button-sm p-button-secondary"
              onClick={onEditClick}
            />
          )}

          <Button
            label="Pozvánka"
            className="p-button-sm p-button-secondary"
            onClick={onInvitationClick}
            disabled={!session?.invitation?.id}
          />
        </div>
      </div>

      <div className="col-12">
        <strong>Rada:</strong> {session?.council || ""}
      </div>

      <div className="col-12">
        <strong>Číslo:</strong> {session?.number || ""}
      </div>

      <div className="col-12">
        <strong>Datum:</strong> {session?.date || ""}
      </div>

      <div className="col-12">
        <strong>Místo:</strong> {session?.place || ""}
      </div>

      <div className="col-12">
        <strong>Stav:</strong>{" "}
        {
          constants?.sessionState.find(
            (state) => state.value === session?.state,
          )?.label
        }
      </div>
    </div>
  );
};

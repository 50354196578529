import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTopicCreate } from "../../api/queries/topic/create";
import { TopicCreatePayload, TopicUpdatePayload } from "../../api/types/topic";
import { useQueryClient } from "react-query";
import { queryKeys } from "../../api/query";
import { useConstants } from "../../api/queries/enum/constants";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useTopicUpdate } from "../../api/queries/topic/update";
import { useTopicDetail } from "../../api/queries/topic/detail";
import { HTMLEditor, defaultEditorModules } from "../../shared/editor";
import { MDXEditor, MDXEditorMethods } from "@mdxeditor/editor/MDXEditor";
import { listsPlugin } from "@mdxeditor/editor/plugins/lists";
import { toolbarPlugin } from "@mdxeditor/editor/plugins/toolbar";
import { BoldItalicUnderlineToggles } from "@mdxeditor/editor/plugins/toolbar/components/BoldItalicUnderlineToggles";
import { ListsToggle } from "@mdxeditor/editor/plugins/toolbar/components/ListsToggle";

export const TopicForm = () => {
  let { topicId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: constantsResponse } = useConstants();
  const { data: topicResponse } = useTopicDetail(Number(topicId));
  const topicCreate = useTopicCreate();
  const topicUpdate = useTopicUpdate(Number(topicId));

  const [topicType, setTopicType] = useState<string>("");
  const onTopicTypeChange = (event: DropdownChangeEvent) =>
    setTopicType(event.value);

  const [name, setName] = useState<string>("");
  const onNameChange = (event: ChangeEvent<HTMLInputElement>) =>
    setName(event.target.value);

  const [state, setState] = useState<string>("");
  const onStateChange = (event: DropdownChangeEvent) => setState(event.value);

  const [topicRef, setTopicRef] = useState<string>("");
  const onTopicRefChange = (event: ChangeEvent<HTMLInputElement>) =>
    setTopicRef(event.target.value);

  const [submittedBy, setSubmittedBy] = useState<string>("");
  const onSubmittedByChange = (event: ChangeEvent<HTMLInputElement>) =>
    setSubmittedBy(event.target.value);

  const [processedBy, setProcessedBy] = useState<string>("");
  const onProcessedByChange = (event: ChangeEvent<HTMLInputElement>) =>
    setProcessedBy(event.target.value);

  const [invitedPersons, setInvitedPersons] = useState<string>("");
  const onPrizvaneOsobyChange = (event: ChangeEvent<HTMLInputElement>) =>
    setInvitedPersons(event.target.value);

  const [reasoning, setReasoning] = useState<string>("");
  const onReasoningChange = (value: string) => setReasoning(value);

  const [resolutionDraft, setResolutionDraft] = useState<string>("");
  const onResolutionDraftChange = (value: string) => setResolutionDraft(value);
  const mdxRef = useRef<MDXEditorMethods>(null);

  const onSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (topicId) {
      const payload: TopicUpdatePayload = {
        name,
        state,
        topicRef,
        topicType,
        submittedBy,
        processedBy,
        invitedPersons,
        reasoning,
        resolutionDraft,
      };

      await topicUpdate.mutateAsync(payload);

      queryClient.invalidateQueries(queryKeys.topicDetail(Number(topicId)), {
        refetchInactive: true,
      });

      navigate(`/topics/${topicId}/detail`);
    } else {
      const payload: TopicCreatePayload = {
        name,
        state,
        topicRef,
        topicType,
        submittedBy,
        processedBy,
        invitedPersons,
        reasoning,
        resolutionDraft,
      };

      const { results } = await topicCreate.mutateAsync(payload);
      navigate(`/topics/${results.topicDetail.data.id}/detail`);
    }

    queryClient.invalidateQueries(queryKeys.topicList(), {
      refetchInactive: true,
    });
  };

  useEffect(() => {
    if (topicResponse) {
      const topic = topicResponse.results.topicDetail.data;

      setName(topic.name);
      setState(topic.state);
      setTopicRef(topic.topicRef);
      setTopicType(topic.topicType);
      setSubmittedBy(topic.submittedBy);
      setProcessedBy(topic.processedBy);
      setInvitedPersons(topic.invitedPersons);
      setReasoning(topic.reasoning);
      setResolutionDraft(topic.resolutionDraft);

      const resolutionDraft = topic.resolutionDraft
        .replaceAll("\\", "")
        .replaceAll("<", "\\<");

      mdxRef.current?.setMarkdown(resolutionDraft);
    }
  }, [topicResponse]);

  return (
    <form className="col-12 fadein" onSubmit={onSubmit}>
      <div className="p-fluid">
        <div className="grid formgrid">
          <div className="field col pt-3">
            <div className="p-float-label">
              <Dropdown
                value={topicType || ""}
                options={
                  constantsResponse?.results.constants.data.topicType || []
                }
                onChange={onTopicTypeChange}
                optionValue={"value"}
                optionLabel={"label"}
              />
              <label>Typ</label>
            </div>
          </div>

          <div className="field col pt-3">
            <div className="p-float-label">
              <InputText value={name || ""} onChange={onNameChange} />
              <label>Název</label>
            </div>
          </div>
        </div>

        <div className="grid formgrid">
          <div className="field col pt-3">
            <div className="p-float-label">
              <Dropdown
                value={state || ""}
                options={
                  constantsResponse?.results.constants.data.topicState || []
                }
                onChange={onStateChange}
                optionValue={"value"}
                optionLabel={"label"}
              />
              <label>Stav</label>
            </div>
          </div>

          <div className="field col pt-3">
            <div className="p-float-label">
              <InputText value={topicRef || ""} onChange={onTopicRefChange} />
              <label>Číslo jednací</label>
            </div>
          </div>
        </div>

        <div className="grid formgrid">
          <div className="field col pt-3">
            <div className="p-float-label">
              <InputText
                value={submittedBy || ""}
                onChange={onSubmittedByChange}
              />
              <label>Předkladatel</label>
            </div>
          </div>

          <div className="field col pt-3">
            <div className="p-float-label">
              <InputText
                value={processedBy || ""}
                onChange={onProcessedByChange}
              />
              <label>Zpracovatel</label>
            </div>
          </div>
        </div>

        <div className="grid formgrid">
          <div className="field col pt-3">
            <div className="p-float-label">
              <InputText
                value={invitedPersons || ""}
                onChange={onPrizvaneOsobyChange}
              />
              <label>Přizvané osoby</label>
            </div>
          </div>

          <div className="field col pt-3">{/* empty */}</div>
        </div>

        <div className="field">
          <small className="text-color-secondary pl-2 pb-2 block">
            Zdůvodnění
          </small>
          <HTMLEditor
            content={reasoning || ""}
            onChange={onReasoningChange}
            modules={defaultEditorModules}
          />
        </div>

        <div className="field">
          <small className="text-color-secondary pl-2 pb-2 block">
            Návrh usnesení
          </small>

          <MDXEditor
            ref={mdxRef}
            markdown={resolutionDraft}
            plugins={[
              toolbarPlugin({
                toolbarContents: () => (
                  <>
                    <BoldItalicUnderlineToggles />
                    <ListsToggle />
                  </>
                ),
              }),
              listsPlugin(),
            ]}
            onChange={onResolutionDraftChange}
          />
        </div>
      </div>

      <div className="flex justify-content-end">
        <Button label="Uložit" className="p-button-sm p-button-secondary" />
      </div>
    </form>
  );
};

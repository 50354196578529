import ReactQuill from "react-quill";

export const HTMLEditor = (props: EditorProps) => {
  let { content, onChange, modules } = props;

  return (
    <>
      <ReactQuill
        theme="snow"
        modules={{ toolbar: modules }}
        value={content}
        onChange={onChange}
      />
    </>
  );
};

export const defaultEditorModules: Array<Module> = [
  "bold",
  "italic",
  "underline",
  { list: "ordered" },
  { list: "bullet" },
];

export interface EditorProps {
  content: string;
  onChange: (content: string) => void;
  modules: Array<Module>;
}

export type Module = string | { [key: string]: string };

import { useMutation } from "react-query";
import api from "../../client";
import {
  SessionCreatePayload,
  SessionCreateResponseData,
} from "../../types/session";

const req = async (
  payload: SessionCreatePayload,
): Promise<SessionCreateResponseData> => {
  const { data } = await api.post(`/sessions`, payload);

  return data;
};

export const useSessionCreate = () => {
  return useMutation((payload: SessionCreatePayload) => {
    return req(payload);
  });
};

import { QueryObserverBaseResult, useQuery } from "react-query";
import { queryKeys } from "../../query";
import api from "../../client";
import { QueryParams } from "../../types/base";
import { toQueryString } from "../../../shared/query";
import { SessionListResponseData } from "../../types/session";

export const req = async (params: QueryParams) => {
  const { data } = await api.get<SessionListResponseData>(
    `/sessions${toQueryString(params)}`,
  );

  return data;
};

export const useSessionList = (
  params: QueryParams,
): QueryObserverBaseResult<SessionListResponseData> => {
  const { data, ...props } = useQuery(queryKeys.sessionListPage(params), () =>
    req(params),
  );
  return { data, ...props };
};

import { QueryObserverBaseResult, useQuery } from "react-query";
import { queryKeys } from "../../query";
import api from "../../client";
import { SessionDetailResponseData } from "../../types/session";

export const req = async (
  id: number | undefined,
): Promise<SessionDetailResponseData> => {
  const { data } = await api.get<SessionDetailResponseData>(`/sessions/${id}`);

  return data;
};

export const useSessionDetail = (
  id: number | undefined,
): QueryObserverBaseResult<SessionDetailResponseData> => {
  const { data, ...props } = useQuery(
    queryKeys.sessionDetail(id),
    () => req(id),
    {
      enabled: Boolean(id),
    },
  );
  return { data, ...props };
};

import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { useConstants } from "../../api/queries/enum/constants";
import { useItemDetail } from "../../api/queries/item/detail";
import { useItemDelete } from "../../api/queries/item/delete";
import { queryKeys } from "../../api/query";
import { useQueryClient } from "react-query";

export const ItemDetail = () => {
  const { sessionId, invitationId, itemId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: itemDetailResponse } = useItemDetail(Number(itemId));
  const { data: constantsResponse } = useConstants();
  const itemDelete = useItemDelete(Number(itemId));

  const onEditClick = () =>
    navigate(
      `/sessions/${sessionId}/invitations/${invitationId}/items/${itemId}/edit`,
    );

  const onDeleteClick = async () => {
    await itemDelete.mutateAsync();

    queryClient.invalidateQueries(queryKeys.itemList(), {
      refetchInactive: true,
    });

    navigate(`/sessions/${sessionId}/invitations/${invitationId}/items`);
  };

  const constants = constantsResponse?.results.constants.data;
  const item = itemDetailResponse?.results.itemDetail.data;

  return (
    <div className="fadein">
      <div className="col-12">
        <div className="flex gap-3">
          <Button
            label="Upravit"
            className="p-button-sm p-button-secondary"
            onClick={onEditClick}
          />

          <Button
            label="Smazat"
            className="p-button-sm p-button-secondary"
            onClick={onDeleteClick}
          />
        </div>
      </div>

      <div className="col-12">
        <strong>Název:</strong> {item?.name || ""}
      </div>

      <div className="col-12">
        <strong>Předkladatel:</strong> {item?.submittedBy || ""}
      </div>

      <div className="col-12">
        <strong>Přizvané osoby:</strong> {item?.invitedPersons || ""}
      </div>

      <div className="col-12">
        <strong>Stav materiálu:</strong>{" "}
        {
          constants?.itemTopic.find(
            (itemTopic) => itemTopic.value === item?.itemTopic,
          )?.label
        }
      </div>

      <div className="col-12">
        <strong>Poznámka:</strong> {item?.remark || ""}
      </div>

      <div className="col-12">
        <strong>Předpokládaná doba projednání:</strong>{" "}
        {item?.estimatedHearingTime || ""}
      </div>
    </div>
  );
};

import { QueryObserverBaseResult, useQuery } from "react-query";
import { queryKeys } from "../../query";
import api from "../../client";
import { ItemDetailResponseData } from "../../types/item";

export const req = async (
  id: number | undefined,
): Promise<ItemDetailResponseData> => {
  const { data } = await api.get<ItemDetailResponseData>(`/items/${id}`);

  return data;
};

export const useItemDetail = (
  id: number | undefined,
): QueryObserverBaseResult<ItemDetailResponseData> => {
  const { data, ...props } = useQuery(queryKeys.itemDetail(id), () => req(id), {
    enabled: Boolean(id),
  });
  return { data, ...props };
};

import { useMutation } from "react-query";
import api from "../../client";

const req = async (id: number | undefined): Promise<{}> => {
  const { data } = await api.delete(`/topics/${id}`);

  return data;
};

export const useTopicDelete = () => {
  return useMutation((id: number | undefined) => {
    return req(id);
  });
};

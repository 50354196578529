import { useMutation } from "react-query";
import api from "../../client";
import { TopicCreatePayload, TopicCreateResponseData } from "../../types/topic";

const req = async (
  payload: TopicCreatePayload,
): Promise<TopicCreateResponseData> => {
  const { data } = await api.post(`/topics`, payload);

  return data;
};

export const useTopicCreate = () => {
  return useMutation((payload: TopicCreatePayload) => {
    return req(payload);
  });
};

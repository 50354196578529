import { QueryObserverBaseResult, useQuery } from "react-query";
import { queryKeys } from "../../query";
import api from "../../client";
import { ConstantsResponseData } from "../../types/enum";

export const req = async (): Promise<ConstantsResponseData> => {
  const { data } = await api.get<ConstantsResponseData>(`/constants`);

  return data;
};

export const useConstants =
  (): QueryObserverBaseResult<ConstantsResponseData> => {
    const { data, ...props } = useQuery(queryKeys.constants(), () => req(), {});
    return { data, ...props };
  };

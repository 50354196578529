import moment from "moment";

export const formatDateTime = (date: string): string | null => {
  let mDate = moment(date);

  if (mDate.isValid()) {
    return mDate.format("MM/DD/YYYY h:mm:ss a");
  }

  return null;
};

export const parseQueryString = (str: string): any => {
  const obj: any = {};

  str
    .replace("?", "")
    .split("&")
    .map((param) => param.split("="))
    .map((params) => ({ key: params[0], value: params[1] }))
    .forEach(({ key, value }) => (obj[key] = +value || value)); // try to cast to number

  // parse sort query param
  //   param has this format:
  //      <column_name> (eg. name, type, ...) for ASC sorted column
  //     -<column_name> (eg. -name, -type, ...) for DESC sorted column
  //   output is 2 fields:
  //     sortField = <column_name>
  //     sortOrder = -1 (DESC) | 1 (ASC)

  obj.sortField = "";
  obj.sortOrder = 0;

  if (obj.hasOwnProperty("order[]")) {
    obj.sortField = obj["order[]"].replace("-", "");
    obj.sortOrder = obj["order[]"].startsWith("-") ? -1 : 1;
  }

  return obj;
};

export const toQueryString = (obj: any): string => {
  // if (obj.sortField) {
  //   obj.order = `${obj.sortField}${obj.sortOrder === 1 ? "" : "_desc"}`
  // }

  let queryString = Object.keys(obj)
    .filter((key) => key !== "sortField")
    .filter((key) => key !== "sortOrder")
    .filter((key) => obj[key] !== undefined)
    .filter((key) => obj[key] !== null)
    .filter((key) => obj[key] !== "")
    .map((key) => `${key}=${obj[key]}`)
    .join("&");

  return queryString ? `?${queryString}` : "";
};

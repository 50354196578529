import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { queryKeys } from "../../api/query";
import { InputText } from "primereact/inputtext";
import { InputNumber, InputNumberChangeEvent } from "primereact/inputnumber";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import {
  SessionCreatePayload,
  SessionUpdatePayload,
} from "../../api/types/session";
import { useSessionCreate } from "../../api/queries/session/create";
import moment from "moment";
import { useSessionDetail } from "../../api/queries/session/detail";
import { useSessionUpdate } from "../../api/queries/session/update";

export const SessionForm = () => {
  const { sessionId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: sessionDetailResponse } = useSessionDetail(Number(sessionId));
  const sessionCreate = useSessionCreate();
  const sessionUpdate = useSessionUpdate(Number(sessionId));

  const [council, setCouncil] = useState<string>("");
  const onCouncilChange = (event: ChangeEvent<HTMLInputElement>) =>
    setCouncil(event.target.value);

  const [number, setNumber] = useState<number | null>(null);
  const onNumberChange = (event: InputNumberChangeEvent) =>
    setNumber(event.value);

  const [date, setDate] = useState<string | Date | Date[] | null>(null);
  const onDateChange = (event: CalendarChangeEvent) =>
    setDate(event.value as Date); // TODO

  const [place, setPlace] = useState<string>("");
  const onPlaceChange = (event: ChangeEvent<HTMLInputElement>) =>
    setPlace(event.target.value);

  const onSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (sessionId) {
      const payload: SessionUpdatePayload = {
        council,
        number: number || 0, // TODO
        date: moment(date as string).toISOString(),
        place,
        state: "MIMORADNE",
      };

      await sessionUpdate.mutateAsync(payload);

      queryClient.invalidateQueries(
        queryKeys.sessionDetail(Number(sessionId)),
        {
          refetchInactive: true,
        },
      );

      navigate(`/sessions/${sessionId}/detail`);
    } else {
      const payload: SessionCreatePayload = {
        council,
        number: number || 0, // TODO
        date: moment(date as string).toISOString(),
        place,
        state: "MIMORADNE",
      };

      const { results } = await sessionCreate.mutateAsync(payload);
      navigate(`/sessions/${results.sessionDetail.data.id}/detail`);
    }

    queryClient.invalidateQueries(queryKeys.sessionList(), {
      refetchInactive: true,
    });
  };

  useEffect(() => {
    if (sessionDetailResponse) {
      const session = sessionDetailResponse.results.sessionDetail.data;

      setCouncil(session.council);
      setNumber(session.number);
      setDate(moment(session.date).toDate());
      setPlace(session.place);
    }
  }, [sessionDetailResponse]);

  return (
    <form className="col-12 fadein" onSubmit={onSubmit}>
      <div className="p-fluid">
        <div className="grid formgrid">
          <div className="field col pt-3">
            <div className="p-float-label">
              <InputText value={council || ""} onChange={onCouncilChange} />
              <label>Rada</label>
            </div>
          </div>

          <div className="field col pt-3">
            <div className="p-float-label">
              <InputNumber value={number || null} onChange={onNumberChange} />
              <label>Číslo</label>
            </div>
          </div>
        </div>

        <div className="grid formgrid">
          <div className="field col pt-3">
            <div className="p-float-label">
              <Calendar value={date} onChange={onDateChange} />
              <label>Datum</label>
            </div>
          </div>

          <div className="field col pt-3">
            <div className="p-float-label">
              <InputText value={place || ""} onChange={onPlaceChange} />
              <label>Místo</label>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-content-end">
        <Button label="Uložit" className="p-button-sm p-button-secondary" />
      </div>
    </form>
  );
};

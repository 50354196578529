import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  DataTable,
  DataTableRowClickEvent,
  DataTableRowReorderEvent,
  DataTableStateEvent,
} from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import { useEffect, useState } from "react";
import { parseQueryString } from "../../shared/query";
import { useItemList } from "../../api/queries/item/list";
import { Button } from "primereact/button";
import { ItemListItem, ItemUpdatePayload } from "../../api/types/item";
import { useItemOrderChange } from "../../api/queries/item/order-change";
import { queryClient, queryKeys } from "../../api/query";
import { InputText } from "primereact/inputtext";
import shared from "../../shared/shared.module.css";

export const InvitationDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sessionId, invitationId, itemId } = useParams();
  const queryParams = parseQueryString(useLocation().search);
  const limit = 999;
  const [offset, setOffset] = useState(queryParams.offset || 0);
  const [total, setTotal] = useState(0);
  const [sortField, setSortField] = useState(queryParams.sortField);
  const [sortOrder, setSortOrder] = useState(queryParams.sortOrder);
  const onPage = (event: DataTableStateEvent) => setOffset(event.first);
  const onSort = (event: DataTableStateEvent) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);
  };
  const itemOrderChange = useItemOrderChange();

  const rowClassName = () =>
    classNames({
      "cursor-pointer": true,
    });

  const onRowClick = (event: DataTableRowClickEvent) =>
    navigate(
      `/sessions/${sessionId}/invitations/${invitationId}/items/${event.data.id}/detail`,
    );

  // TODO: for now this only navigates back
  const onSaveClick = () => navigate(`/sessions/${sessionId}/detail`);

  const onAddItemClick = () =>
    navigate(`/sessions/${sessionId}/invitations/${invitationId}/items/new`);

  const onGenerateInvitationClick = () =>
    window.open(
      `${process.env.REACT_APP_API_BASE_URL}/invitations/${invitationId}/html`,
    );

  const { data: itemListResponse, isLoading } = useItemList({
    perPage: limit,
    page: offset / limit + 1,
    session_id: sessionId,
    "order[]": "order",
  });

  const onRowReOrder = async (
    event: DataTableRowReorderEvent<ItemListItem[]>,
  ) => {
    const fromIndex = event.dragIndex;
    const toIndex = event.dropIndex + 1;

    const item = itemListResponse?.results.itemList.data.at(fromIndex);

    if (item) {
      const payload: ItemUpdatePayload = {
        id: item.id,
        order: toIndex,
        name: item.name,
        submittedBy: item.submittedBy,
        invitedPersons: item.invitedPersons,
        remark: item.remark,
        estimatedHearingTime: item.estimatedHearingTime,
        itemTopic: item.itemTopic,
      };

      await itemOrderChange.mutateAsync(payload);

      queryClient.invalidateQueries(queryKeys.itemList(), {
        refetchInactive: true,
      });
    }
  };

  useEffect(() => {
    if (itemListResponse) {
      setTotal(itemListResponse.results.itemList.total);
    }
  }, [itemListResponse]);

  const isListOnly = !itemId && location.pathname.indexOf("new") === -1;

  return (
    <div className="fadein">
      <div className={shared.submenu}>
        <h3 className="mt-0">Pozvánka</h3>

        <div className="flex gap-3">
          <Button
            label="Uložit"
            className="p-button-sm p-button-secondary"
            onClick={onSaveClick}
          />

          <Button
            label="Přidat bod"
            className="p-button-sm p-button-secondary"
            onClick={onAddItemClick}
          />

          <Button
            label="Vygenerovat pozvánku"
            className="p-button-sm p-button-secondary"
            onClick={onGenerateInvitationClick}
          />
        </div>

        <div className="mt-3">
          <InputText placeholder="Hledat..." />
        </div>
      </div>

      <div className={"flex flex-column md:flex-row"}>
        <div className={shared.contentColumn}>
          <DataTable
            emptyMessage={"Žádné záznamy"}
            paginator={false}
            lazy={false}
            rows={limit}
            first={offset}
            totalRecords={total}
            loading={isLoading}
            sortField={sortField}
            sortOrder={sortOrder}
            onPage={onPage}
            onSort={onSort}
            value={itemListResponse?.results.itemList.data || []}
            stripedRows={true}
            className="p-datatable-sm"
            rowClassName={rowClassName}
            rowHover={true}
            onRowClick={onRowClick}
            reorderableRows={true}
            onRowReorder={onRowReOrder}
          >
            <Column rowReorder={true} style={{ width: "3rem" }} />
            <Column field="name" header="Název" />
            <Column field="submittedBy" header="Předkladatel" />
          </DataTable>
        </div>

        {!isListOnly && (
          <div className={shared.contentColumn}>
            <Outlet />
          </div>
        )}
      </div>
    </div>
  );
};

import { useMutation } from "react-query";
import api from "../../client";
import {
  SessionUpdatePayload,
  SessionUpdateResponseData,
} from "../../types/session";

const req = async (
  id: number | undefined,
  payload: SessionUpdatePayload,
): Promise<SessionUpdateResponseData> => {
  const { data } = await api.put(`/sessions/${id}`, payload);

  return data;
};

export const useSessionUpdate = (id: number | undefined) => {
  return useMutation((payload: SessionUpdatePayload) => {
    return req(id, payload);
  });
};

import { useMutation } from "react-query";
import api from "../../client";
import { ItemUpdatePayload, ItemUpdateResponseData } from "../../types/item";

const req = async (
  id: number | undefined,
  payload: ItemUpdatePayload,
): Promise<ItemUpdateResponseData> => {
  const { data } = await api.put(`/items/${id}`, payload);

  return data;
};

export const useItemUpdate = (id: number | undefined) => {
  return useMutation((payload: ItemUpdatePayload) => {
    return req(id, payload);
  });
};

import { useMutation } from "react-query";
import api from "../../client";
import { AuthLoginPayload, AuthLoginResponseData } from "../../types/auth";

const req = async (
  payload: AuthLoginPayload,
): Promise<AuthLoginResponseData> => {
  const { data } = await api.post(`/login`, payload);

  return data;
};

export const useLogin = () => {
  return useMutation((payload: AuthLoginPayload) => {
    return req(payload);
  });
};

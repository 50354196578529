import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useItemCreate } from "../../api/queries/item/create";
import { ItemCreatePayload, ItemUpdatePayload } from "../../api/types/item";
import { InputText } from "primereact/inputtext";
import { InputNumber, InputNumberChangeEvent } from "primereact/inputnumber";
import { useConstants } from "../../api/queries/enum/constants";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useQueryClient } from "react-query";
import { queryKeys } from "../../api/query";
import { useItemDetail } from "../../api/queries/item/detail";
import { useItemUpdate } from "../../api/queries/item/update";

export const ItemForm = () => {
  const { sessionId, invitationId, itemId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const itemCreate = useItemCreate();
  const itemUpdate = useItemUpdate(Number(itemId));

  const { data: constantsResponse } = useConstants();
  const { data: itemDetailResponse } = useItemDetail(Number(itemId));

  const [name, setName] = useState<string>("");
  const onNameChange = (event: ChangeEvent<HTMLInputElement>) =>
    setName(event.target.value);

  const [submittedBy, setSubmittedBy] = useState<string>("");
  const onSubmittedByChange = (event: ChangeEvent<HTMLInputElement>) =>
    setSubmittedBy(event.target.value);

  const [invitedPersons, setInvitedPersons] = useState<string>("");
  const onInvitedPersonsChange = (event: ChangeEvent<HTMLInputElement>) =>
    setInvitedPersons(event.target.value);

  const [itemTopic, setItemTopic] = useState<string>("");
  const onItemTopicChange = (event: DropdownChangeEvent) =>
    setItemTopic(event.value);

  const [remark, setRemark] = useState<string>("");
  const onRemarkChange = (event: ChangeEvent<HTMLInputElement>) =>
    setRemark(event.target.value);

  const [estimatedHearingTime, setEstimatedHearingTime] = useState<
    number | null
  >(null);
  const onEstimatedHearingTimeChange = (event: InputNumberChangeEvent) =>
    setEstimatedHearingTime(event.value);

  const onSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (itemId) {
      const payload: ItemUpdatePayload = {
        id: Number(itemId),
        name,
        submittedBy,
        invitedPersons,
        itemTopic,
        remark,
        estimatedHearingTime: estimatedHearingTime || 0,
      };

      await itemUpdate.mutateAsync(payload);

      queryClient.invalidateQueries(queryKeys.itemDetail(Number(itemId)), {
        refetchInactive: true,
      });
    } else {
      const payload: ItemCreatePayload = {
        session: {
          id: Number(sessionId),
        },
        name,
        submittedBy,
        invitedPersons,
        itemTopic,
        remark,
        estimatedHearingTime: estimatedHearingTime || 0,
      };

      await itemCreate.mutateAsync(payload);
    }

    queryClient.invalidateQueries(queryKeys.itemList(), {
      refetchInactive: true,
    });

    navigate(`/sessions/${sessionId}/invitations/${invitationId}/items`);
  };

  useEffect(() => {
    if (itemDetailResponse) {
      const item = itemDetailResponse.results.itemDetail.data;

      setName(item.name);
      setSubmittedBy(item.submittedBy);
      setInvitedPersons(item.invitedPersons || "");
      setItemTopic(item.itemTopic || "");
      setRemark(item.remark || "");
      setEstimatedHearingTime(item.estimatedHearingTime);
    }
  }, [itemDetailResponse]);

  return (
    <form className="col-12 fadein" onSubmit={onSubmit}>
      <div className="p-fluid">
        <div className="grid formgrid">
          <div className="field col pt-3">
            <div className="p-float-label">
              <InputText value={name} onChange={onNameChange} />
              <label>Název</label>
            </div>
          </div>

          <div className="field col pt-3">
            <div className="p-float-label">
              <InputText value={submittedBy} onChange={onSubmittedByChange} />
              <label>Předkladatel</label>
            </div>
          </div>
        </div>

        <div className="grid formgrid">
          <div className="field col pt-3">
            <div className="p-float-label">
              <InputText
                value={invitedPersons}
                onChange={onInvitedPersonsChange}
              />
              <label>Přizvané osoby</label>
            </div>
          </div>

          <div className="field col pt-3">
            <div className="p-float-label">
              <Dropdown
                options={
                  constantsResponse?.results.constants.data.itemTopic || []
                }
                value={itemTopic}
                onChange={onItemTopicChange}
                optionLabel="label"
                optionValue="value"
              />
              <label>Stav materiálu</label>
            </div>
          </div>
        </div>

        <div className="grid formgrid">
          <div className="field col pt-3">
            <div className="p-float-label">
              <InputText value={remark} onChange={onRemarkChange} />
              <label>Poznámka</label>
            </div>
          </div>

          <div className="field col pt-3">
            <div className="p-float-label">
              <InputNumber
                value={estimatedHearingTime}
                onChange={onEstimatedHearingTimeChange}
              />
              <label>Předpokládaná doba projednání</label>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-content-end">
        <Button label="Uložit" className="p-button-sm p-button-secondary" />
      </div>
    </form>
  );
};

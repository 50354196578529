import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { useTopicDetail } from "../../api/queries/topic/detail";
import { useConstants } from "../../api/queries/enum/constants";
import MarkdownPreview from "@uiw/react-markdown-preview";

export const TopicDetail = () => {
  const { topicId } = useParams();
  const navigate = useNavigate();
  const { data: topicDetailResponse } = useTopicDetail(Number(topicId));
  const { data: constantsResponse } = useConstants();

  const onEditClick = () => navigate(`/topics/${topicId}/edit`);

  const reasoningHTML = topicDetailResponse?.results.topicDetail.data.reasoning;
  const resolutionDraftHTML =
    topicDetailResponse?.results.topicDetail.data.resolutionDraft;

  return (
    <div className="fadein">
      <div className="col-12">
        <div className="flex gap-3">
          <Button
            label="Upravit"
            className="p-button-sm p-button-secondary"
            onClick={onEditClick}
          />

          {/* <Button
            label="Odeslat e-mailem"
            className="p-button-sm p-button-secondary"
          />

          <Button
            label="Zkopírovat"
            className="p-button-sm p-button-secondary"
          />

          <Button
            label="Připojit k zasedání"
            className="p-button-sm p-button-secondary"
          /> */}
        </div>
      </div>

      <div className="col-12">
        <strong>Nazev:</strong>{" "}
        {topicDetailResponse?.results.topicDetail.data.name}
      </div>

      <div className="col-12">
        <strong>Stav: </strong>
        {
          constantsResponse?.results.constants.data.topicState.find(
            (stav) =>
              stav.value ===
              topicDetailResponse?.results.topicDetail.data.state,
          )?.label
        }
      </div>

      <div className="col-12">
        <strong>Číslo jednací: </strong>
        {topicDetailResponse?.results.topicDetail.data.topicRef}
      </div>

      <div className="col-12">
        <strong>Typ materiálu: </strong>
        {
          constantsResponse?.results.constants.data.topicType.find(
            (typ) =>
              typ.value ===
              topicDetailResponse?.results.topicDetail.data.topicType,
          )?.label
        }
      </div>

      <div className="col-12">
        <strong>Předkladatel: </strong>
        {topicDetailResponse?.results.topicDetail.data.submittedBy}
      </div>

      <div className="col-12">
        <strong>Zpracovatel: </strong>
        {topicDetailResponse?.results.topicDetail.data.processedBy}
      </div>

      <div className="col-12">
        <strong>Přizvané osoby: </strong>
        {topicDetailResponse?.results.topicDetail.data.invitedPersons}
      </div>

      <div className="col-12">
        <strong>Zdůvodnění: </strong>
        {reasoningHTML && (
          <div
            className="surface-ground border-1 border-300 px-3"
            dangerouslySetInnerHTML={{
              __html: reasoningHTML || "",
            }}
          />
        )}
      </div>

      <div className="col-12">
        <strong>Návrh usnesení: </strong>
        {resolutionDraftHTML && (
          <div className="surface-ground border-1 border-300 px-3">
            <MarkdownPreview
              source={resolutionDraftHTML}
              wrapperElement={{
                "data-color-mode": "light",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

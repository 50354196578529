import { QueryObserverBaseResult, useQuery } from "react-query";
import { queryKeys } from "../../query";
import api from "../../client";
import { toQueryString } from "../../../shared/query";
import { ItemListQueryParams, ItemListResponseData } from "../../types/item";

export const req = async (params: ItemListQueryParams) => {
  const { data } = await api.get<ItemListResponseData>(
    `/items${toQueryString(params)}`,
  );

  return data;
};

export const useItemList = (
  params: ItemListQueryParams,
): QueryObserverBaseResult<ItemListResponseData> => {
  const { data, ...props } = useQuery(queryKeys.itemListPage(params), () =>
    req(params),
  );
  return { data, ...props };
};

import { useMutation } from "react-query";
import api from "../../client";
import { ItemCreatePayload, ItemCreateResponseData } from "../../types/item";

const req = async (
  payload: ItemCreatePayload,
): Promise<ItemCreateResponseData> => {
  const { data } = await api.post(`/items`, payload);

  return data;
};

export const useItemCreate = () => {
  return useMutation((payload: ItemCreatePayload) => {
    return req(payload);
  });
};

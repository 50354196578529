import { Button } from "primereact/button";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useTopicList } from "../../api/queries/topic/list";
import {
  DataTable,
  DataTableRowClickEvent,
  DataTableSelectionChangeEvent,
  DataTableStateEvent,
} from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import { SyntheticEvent, useEffect, useState } from "react";
import {
  TopicListItem,
  TopicListOrder,
  TopicStav,
} from "../../api/types/topic";
import shared from "../../shared/shared.module.css";
import { InputText } from "primereact/inputtext";
import { parseQueryString } from "../../shared/query";
import { useTopicDelete } from "../../api/queries/topic/delete";
import { queryKeys } from "../../api/query";
import { useQueryClient } from "react-query";
import { useConstants } from "../../api/queries/enum/constants";
import { Tag } from "primereact/tag";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useSessionList } from "../../api/queries/session/list";
import { SessionAddTopicPayload } from "../../api/types/session";
import { useSessionAddTopic } from "../../api/queries/session/add-topic";
import { Dialog } from "primereact/dialog";

export const TopicLayout = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();
  const queryParams = parseQueryString(location.search);
  const limit = 20;
  const [offset, setOffset] = useState(queryParams.offset || 0);
  const [total, setTotal] = useState(0);
  const [sortField, setSortField] = useState(queryParams.sortField || "");
  const [sortOrder, setSortOrder] = useState(queryParams.sortOrder || 0);
  const onPage = (event: DataTableStateEvent) => setOffset(event.first);
  const onSort = (event: DataTableStateEvent) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder as number);
  };

  const { data: constantResponse } = useConstants();
  const { data: sessionListResponse } = useSessionList({
    perPage: limit,
    page: offset / limit + 1,
  });
  const topicDelete = useTopicDelete();
  const sessionAddTopic = useSessionAddTopic();

  const onNewClick = () => navigate("/topics/new");

  const onDeleteClick = async () => {
    const deleteTopic = async (id: number) => {
      await topicDelete.mutateAsync(id);
    };

    selectedRows.forEach(async (row) => {
      await deleteTopic(row.id);

      queryClient.invalidateQueries(queryKeys.topicList(), {
        refetchInactive: true,
      });
    });

    setSelectedRows([]);
  };

  const [isSessionTopicDialogOpen, setIsSessionTopicDialogOpen] =
    useState<boolean>(false);
  const onSessionTopicDialogClose = () => setIsSessionTopicDialogOpen(false);
  const [sessionId, setSessionId] = useState<number | null>(null);

  const onSessionChange = (event: DropdownChangeEvent) =>
    setSessionId(event.value);

  const onConnectToSessionClick = (event: SyntheticEvent) =>
    setIsSessionTopicDialogOpen(true);
  const onConnectToSessionSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (sessionId) {
      const payload: SessionAddTopicPayload = {
        sessionId: sessionId,
        topics: selectedRows.map((row) => row.id),
      };

      await sessionAddTopic.mutateAsync(payload);
      setIsSessionTopicDialogOpen(false);
    }

    queryClient.invalidateQueries(queryKeys.topicList(), {
      refetchInactive: true,
    });

    selectedRows.forEach((row) => {
      queryClient.invalidateQueries(queryKeys.topicDetail(row.id), {
        refetchInactive: true,
      });
    });
  };

  const rowClassName = () =>
    classNames({
      "cursor-pointer": true,
    });

  const [selectedRows, setSelectedRows] = useState<TopicListItem[]>([]);

  const onSelectionChange = (
    event: DataTableSelectionChangeEvent<TopicListItem[]>,
  ) => setSelectedRows(event.value as TopicListItem[]);

  const onRowClick = (event: DataTableRowClickEvent) => {
    const topic = event.data as TopicListItem;
    navigate(`/topics/${topic.id}/detail`);
  };

  const { data: topicListResponse, isLoading } = useTopicList({
    perPage: limit,
    page: offset / limit + 1,
    "order[]": `${sortOrder === -1 ? "-" : ""}${sortField}` as TopicListOrder,
  });

  const tStav = (row: TopicListItem) => {
    const stav = constantResponse?.results.constants.data.topicState.find(
      (stav) => stav.value === row.state,
    );

    if (stav) {
      return (
        <Tag severity={stavColor[stav.value as TopicStav]} value={stav.label} />
      );
    }
  };

  const tTyp = (row: TopicListItem) => {
    const typ = constantResponse?.results.constants.data.topicType.find(
      (typ) => typ.value === row.topicType,
    );

    if (typ) {
      return typ.label;
    }

    return row.topicType;
  };

  useEffect(() => {
    if (topicListResponse) {
      setTotal(topicListResponse.results.topicList.total);
    }
  }, [topicListResponse]);

  const isListOnly = location.pathname === "/topics";
  const isConnectToSessionDisabled =
    selectedRows.length === 0 ||
    !selectedRows.every((row) => row.state === "02_PRIPRAVEN");

  return (
    <div className="fadein">
      <div className={shared.submenu}>
        <h3 className="mt-0">Materiály</h3>

        <div className="flex gap-3">
          <Button
            label="Nový"
            className="p-button-sm p-button-secondary"
            onClick={onNewClick}
          />

          <Button
            label="Smazat"
            className="p-button-sm p-button-secondary"
            disabled={selectedRows.length === 0}
            onClick={onDeleteClick}
          />

          <Button
            label="Připojit k zasedání"
            className="p-button-sm p-button-secondary"
            disabled={isConnectToSessionDisabled}
            onClick={onConnectToSessionClick}
          />

          <Dialog
            header={"Připojit k zasedání"}
            visible={isSessionTopicDialogOpen}
            onHide={onSessionTopicDialogClose}
            resizable={false}
            draggable={false}
            style={{ minWidth: 300 }}
          >
            <form className="pt-3" onSubmit={onConnectToSessionSubmit}>
              <div className="p-fluid">
                <div className="field">
                  <div className="p-float-label">
                    <Dropdown
                      options={
                        sessionListResponse?.results.sessionList.data || []
                      }
                      value={sessionId}
                      onChange={onSessionChange}
                      optionValue="id"
                      optionLabel="number"
                    />
                    <label>Zasedání</label>
                  </div>
                </div>
              </div>

              <Button
                type="submit"
                label="Připojit"
                icon={
                  sessionAddTopic.isLoading
                    ? "pi pi-spinner pi-spin"
                    : "pi pi-link"
                }
                className="p-button-sm p-button-secondary"
                disabled={sessionAddTopic.isLoading || !sessionId}
              />
            </form>
          </Dialog>
        </div>

        <div className="mt-3">
          <InputText placeholder="Hledat..." />
        </div>
      </div>

      <div className={"flex flex-column md:flex-row"}>
        <div className={shared.contentColumn}>
          <DataTable
            emptyMessage={"Žádné záznamy"}
            paginator={true}
            lazy={true}
            rows={limit}
            first={offset}
            totalRecords={total}
            loading={isLoading}
            sortField={sortField}
            sortOrder={sortOrder}
            onPage={onPage}
            onSort={onSort}
            value={topicListResponse?.results.topicList.data || []}
            stripedRows={true}
            className="p-datatable-sm"
            rowClassName={rowClassName}
            rowHover={true}
            selectionMode="checkbox"
            selection={selectedRows!}
            onSelectionChange={onSelectionChange}
            onRowClick={onRowClick}
            dataKey="id"
          >
            <Column selectionMode="multiple" headerStyle={{ width: "3rem" }} />
            <Column
              field="stav"
              header="Stav"
              sortable={false}
              sortField="state"
              body={tStav}
            />
            <Column
              field="topicType"
              header="Typ"
              sortable={true}
              sortField="topicType"
              body={tTyp}
            />
            <Column
              field="name"
              header="Název"
              sortable={true}
              sortField="name"
            />
            <Column
              field="submittedBy"
              header="Předkladatel"
              sortable={true}
              sortField="submittedBy"
            />
            <Column
              field="processedBy"
              header="Zpracovatel"
              sortable={true}
              sortField="processedBy"
            />
          </DataTable>
        </div>

        {!isListOnly && (
          <div className={shared.contentColumn}>
            <Outlet />
          </div>
        )}
      </div>
    </div>
  );
};

type StavColor = {
  [key in TopicStav]: "success" | "info" | "warning" | "danger";
};

const stavColor: StavColor = {
  "01_ROZPRACOVAN": "warning",
  "02_PRIPRAVEN": "success",
  "03_PROJEDNAN": "info",
  "04_ARCHIVOVAN": "danger",
};

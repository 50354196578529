import { Menubar } from "primereact/menubar";
import { MenuItem } from "primereact/menuitem";
import { useNavigate, NavLink } from "react-router-dom";
import styles from "./styles.module.css";
import iLogo from "./logo.png";

export const Navbar = () => {
  const navigate = useNavigate();

  const logo = (
    <NavLink to="/">
      <img src={iLogo} alt="Logo Akademie věd ČR" className={styles.logo} />
    </NavLink>
  );

  const model: MenuItem[] = [
    // { label: "Rada", command: () => navigate("/council") },
    { label: "Materiály", command: () => navigate("/topics") },
    { label: "Zasedání", command: () => navigate("/sessions") },
    // { label: "Pozvánky", command: () => navigate("/invitations") },
    { label: "Nastavení", command: () => navigate("/settings") },
  ];

  return <Menubar start={logo} model={model} className={styles.navbar} />;
};

import { useMutation } from "react-query";
import api from "../../client";
import { TopicUpdatePayload, TopicUpdateResponseData } from "../../types/topic";

const req = async (
  id: number | undefined,
  payload: TopicUpdatePayload,
): Promise<TopicUpdateResponseData> => {
  const { data } = await api.put(`/topics/${id}`, payload);

  return data;
};

export const useTopicUpdate = (id: number | undefined) => {
  return useMutation((payload: TopicUpdatePayload) => {
    return req(id, payload);
  });
};

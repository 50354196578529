import { QueryClient } from "react-query";
import { QueryParams } from "./types/base";
import { TopicListQueryParams } from "./types/topic";
import { ItemListQueryParams } from "./types/item";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
    mutations: {},
  },
});

export const queryKeys = {
  isAuthenticated: () => ["is-authenticated"],
  constants: () => ["constants"],
  //
  topicList: () => ["topic-list"],
  topicListPage: (params: TopicListQueryParams) => ["topic-list", params],
  topicDetail: (id: number | undefined) => ["topic-detail", id],
  //
  sessionList: () => ["session-list"],
  sessionListPage: (params: QueryParams) => ["session-list", params],
  sessionDetail: (id: number | undefined) => ["session", id],
  //
  invitationList: () => ["invitation-list"],
  invitationListPage: (params: QueryParams) => ["invitation-list", params],
  invitationDetail: (id: number | undefined) => ["invitation", id],
  //
  itemList: () => ["item-list"],
  itemListPage: (params: ItemListQueryParams) => ["item-list", params],
  itemDetail: (id: number | undefined) => ["item", id],
};

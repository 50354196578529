import { Button } from "primereact/button";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  DataTable,
  DataTableRowClickEvent,
  DataTableStateEvent,
} from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import { useEffect, useState } from "react";
import shared from "../../shared/shared.module.css";
import { InputText } from "primereact/inputtext";
import { parseQueryString } from "../../shared/query";
import { useSessionList } from "../../api/queries/session/list";
import { SessionListItem } from "../../api/types/session";
import { useConstants } from "../../api/queries/enum/constants";

export const SessionLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = parseQueryString(location.search);
  const limit = 20;
  const [offset, setOffset] = useState(queryParams.offset || 0);
  const [total, setTotal] = useState(0);
  const [sortField, setSortField] = useState(queryParams.sortField);
  const [sortOrder, setSortOrder] = useState(queryParams.sortOrder);
  const onPage = (event: DataTableStateEvent) => setOffset(event.first);
  const onSort = (event: DataTableStateEvent) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);
  };

  const onNewClick = () => navigate("/sessions/new");

  const rowClassName = () =>
    classNames({
      "cursor-pointer": true,
    });

  const onRowClick = (event: DataTableRowClickEvent) => {
    const session = event.data as SessionListItem;
    navigate(`/sessions/${session.id}/detail`);
  };

  const { data: constantsResponse } = useConstants();

  const { data: sessionListResponse, isLoading } = useSessionList({
    perPage: limit,
    page: offset / limit + 1,
  });

  const tState = (row: SessionListItem) => {
    const state = constantsResponse?.results.constants.data.sessionState.find(
      (state) => state.value === row.state,
    );

    if (state) {
      return state.label;
    }

    return row.state;
  };

  useEffect(() => {
    if (sessionListResponse) {
      setTotal(sessionListResponse.results.sessionList.total);
    }
  }, [sessionListResponse]);

  const isListOnly = location.pathname === "/sessions";

  return (
    <div className="fadein">
      <div className={shared.submenu}>
        <h3 className="mt-0">Zasedání</h3>

        <div className="flex gap-3">
          <Button
            label="Mimořádné zasedání"
            className="p-button-sm p-button-secondary"
            onClick={onNewClick}
          />
        </div>

        <div className="mt-3">
          <InputText placeholder="Hledat..." />
        </div>
      </div>

      <div className={"flex flex-column md:flex-row"}>
        <div className={shared.contentColumn}>
          <DataTable
            emptyMessage={"Žádné záznamy"}
            paginator={true}
            lazy={true}
            rows={limit}
            first={offset}
            totalRecords={total}
            loading={isLoading}
            sortField={sortField}
            sortOrder={sortOrder}
            onPage={onPage}
            onSort={onSort}
            value={sessionListResponse?.results.sessionList.data || []}
            stripedRows={true}
            className="p-datatable-sm"
            rowClassName={rowClassName}
            rowHover={true}
            onRowClick={onRowClick}
            dataKey="id"
          >
            <Column field="council" header="Rada" />
            <Column field="number" header="Číslo" />
            <Column field="date" header="Datum" />
            <Column field="place" header="Místo" />
            <Column field="state" header="Stav" body={tState} />
          </DataTable>
        </div>

        {!isListOnly && (
          <div className={shared.contentColumn}>
            <Outlet />
          </div>
        )}
      </div>
    </div>
  );
};

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { HashRouter } from "react-router-dom";
import { addLocale, locale } from "primereact/api";
import App from "./App";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./style/global.css";
import { queryClient } from "./api/query";
import { QueryClientProvider } from "react-query";

import "@mdxeditor/editor/style.css";

addLocale("cs", {
  firstDayOfWeek: 1,
  dayNames: [
    "neděle",
    "pondělí",
    "úterý",
    "středa",
    "čtvrtek",
    "pátek",
    "sobota",
  ],
  dayNamesShort: ["ne", "po", "út", "st", "čt", "pá", "so"],
  dayNamesMin: ["ne", "po", "út", "st", "čt", "pá", "so"],
  monthNames: [
    "leden",
    "únor",
    "březen",
    "duben",
    "květen",
    "červen",
    "červenec",
    "srpen",
    "září",
    "říjen",
    "listopad",
    "prosinec",
  ],
  monthNamesShort: [
    "led",
    "úno",
    "bře",
    "dub",
    "kvě",
    "čvn",
    "čvc",
    "srp",
    "zář",
    "říj",
    "lis",
    "pro",
  ],
  today: "dnes",
  clear: "vymazat",
  choose: "vybrat",
});
locale("cs");

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <StrictMode>
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </HashRouter>
  </StrictMode>,
);

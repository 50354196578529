import { useMutation } from "react-query";
import api from "../../client";
import { ItemUpdatePayload, ItemUpdateResponseData } from "../../types/item";

const req = async (
  payload: ItemUpdatePayload,
): Promise<ItemUpdateResponseData> => {
  const { id } = payload;

  const { data } = await api.put(`/items/${id}`, payload);

  return data;
};

export const useItemOrderChange = () => {
  return useMutation((payload: ItemUpdatePayload) => {
    return req(payload);
  });
};

import { useMutation } from "react-query";
import api from "../../client";
import {
  SessionAddTopicPayload,
  SessionAddTopicResponseData,
} from "../../types/session";

const req = async (
  payload: SessionAddTopicPayload,
): Promise<SessionAddTopicResponseData> => {
  const { sessionId, topics } = payload;

  const { data } = await api.patch(`/sessions/${sessionId}/topic`, topics);

  return data;
};

export const useSessionAddTopic = () => {
  return useMutation((payload: SessionAddTopicPayload) => {
    return req(payload);
  });
};
